.blog-post {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    height: 90%;
    position: fixed;
    top: 10%;
    overflow: auto;

}
.blog-page-container {
    height: 100%;
    width:90%;
    padding-inline: 5%;
    font-family: Poppins;
    margin: 0 auto;
    line-height: 1.6;
    max-width: 800px; /* Limit the width of the container for better readability */
}
.blog-page-container h1 {
    color: orange;
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center; /* Center align the title */
}

.blog-page-container h2 {
    color: orange;
    font-size: 1.5em;
    margin: 30px 0 15px;
    text-decoration: orange 2px;
}

.date-and-share {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 0.5em;
}

.share-options button {
    background: none;
    border: none;
    color: #666; /* Change this to the color you want for the icons */
    cursor: pointer;
}

.share-options button:hover {
    color: orange; /* Change this to the color you want when hovering over the icons */
}

.blog-page-container p {
    color: #666;
    font-size: 1em;
    margin-bottom: 15px;
    text-align: justify;
}

/* Added styles for images */
.blog-page-container img {
    max-width: 100%; /* Make sure images don't overflow the container */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Display images as block to add margins */
    margin: 20px auto; /* Center images and add vertical spacing */
}
/* Styles for block quotes */
.blog-page-container blockquote {
    border-left: 4px solid #ccc;
    padding-left: 20px;
    color: #666;
    font-style: italic;
    margin: 20px 0;
}

/* Styles for tables */
.blog-page-container table {
    width: 90%;
    border-collapse: collapse;
    margin: 20px 0;
}
.blog-page-container th, .blog-page-container td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

/* Styles for lists */
.blog-page-container ul, .blog-page-container ol {
    margin: 20px 0;
    padding-left: 40px;
}
.blog-page-container li {
    margin-bottom: 10px;
}
.blog-links {
    font-family: 'Helvetica Neue', sans-serif;
    color: #2c3e50;
    margin: 30px 0;
    background-color: #ecf0f1;
    padding: 20px;
    border-bottom: 2px solid #bdc3c7;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.blog-links:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.blog-links strong {
    color: #2c3e50;
    display: block;
    margin-bottom: 15px;
    font-size: 1.2em;
}

.blog-links a {
    color: #3498db;
    text-decoration: none;
    display: block;
    margin-bottom: 15px;
    padding: 10px 0;
    transition: all 0.3s ease;
}

.blog-links a:hover {
    color: #2980b9;
    text-decoration: underline;
}