/* Blog.css */

.blog-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    height: 90%;
    position: fixed;
    top: 10%;
    overflow: auto;
}

.blog-heading{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
}

.page-title {
    font-size: 2em;
    color: #333;
    text-align: center;
    margin-bottom: 40px;
}

.blogs {
    margin-top: 3%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: auto;
    width: 90%;
    margin-inline: 5%;
}

.blog {
    width: 500px;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.blog:hover {
    transform: translateY(-10px);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 2px solid #000;
}

.blog-title {
    font-size: 1.5em;
    color: orange;
    text-align: center;
    margin-bottom: 10px;
}

.blog-description {
    color: #666;
    text-align: left;
}

