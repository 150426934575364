/* SignUp.css */

.signup-container {
    width: 100%;
    max-width: 600px;
    position: relative;
    top:10vh;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup-title {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
}

.signup-form {
    display: flex;
    flex-direction: column;
    align-items: center; 
}

.signup-form > div {
    margin-bottom: 10px;
}

.error{
    color: red;
    font-size: 0.8em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.thank-you {
    font-size: 1.3em;
    color: orange;
    text-align: center;
    margin-top: 20px;
}
.signup-content{
    width: 90%;
    padding-inline: 5%;
}

/* Responsive styles for mobile */
@media (max-width: 600px) {
    .signup-container {
        padding: 10px;
    }
    .signup-form{
        display: flex;
        flex-direction: column;
        align-items: center; 
        width: 100%;
    }

    .signup-title {
        font-size: 2em;
    }
}

/* Responsive styles for desktop */
@media (min-width: 601px) {
    .signup-container {
        padding: 30px;
    }

    .signup-form{
        display: flex;
        flex-direction: column;
        align-items: center; 
        width: 100%;
    }

    .signup-title {
        font-size: 2.5em;
    }

    .signup-form > div {
        margin-bottom: 20px;
    }
}
/* Add this to your existing CSS file */

.particle {
    position: fixed;
    bottom: 0;
    width: 0;
    height: 0;
    background-color: transparent;
    border-left: 10px solid transparent; /* 2x bigger */
    border-right: 10px solid transparent; /* 2x bigger */
    border-bottom: 20px solid black; /* 2x bigger */
    animation: moveUpFadeOut 6s linear; /* Slower pace */
}

@keyframes moveUpFadeOut {
    0% {
        opacity: 1;
        transform: translateY(0) rotate(0deg);
    }
    100% {
        opacity: 0;
        transform: translateY(-250px) rotate(360deg); /* Rotation added */
    }
}