@import url('https://fonts.googleapis.com/css?family=Roboto:300');

.honkai-star-rail {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    background-color: #000;
    color: #ffffff;
    padding-inline: 5%;
    height: 100%;
    width: 80%;
}

.honkai-star-rail p {
    color: #dcdcdc;
}

.honkai-star-rail h1{
    margin-bottom: 0%!important;
}
@keyframes snake {
    0% {
        transform: translateX(-100%);
    }
    50% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.custom-img {
    border-radius: 5px;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
    margin-bottom: 1rem;
}

.footer {
    background-color: #000;
    color: #ffffff;
    height: 10%;
    width: 100%;
}

.footer-content {
    display: flex;
    justify-content: space-evenly;
}

.footer-links ul,
.footer-info ul {
    list-style-type: none;
    padding: 0;
}

.footer-links a,
.footer-info a {
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

.footer-links a:hover,
.footer-info a:hover {
    color: #ff7d11;
}

.footer-links a[href="/#Platform"],
.footer-info a[href="/#Company"] {
    font-weight: bold;
    color: #ffffff;
    line-height: 1.6;
}

.footer-links a:not([href="/#Platform"]),
.footer-info a:not([href="/#Company"]) {
    font-weight: lighter;
    color: #dcdcdc;
    line-height: 1.5;
}

.footer-copyright {
    text-align: center;
    color: #fff;
}

.canvas-container{
    position: fixed;
    height: 100%;
    width: 100vw;
    z-index: 100;
}

.states-container{
    background-color: black;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    height: 100vh;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
    scroll-snap-type: y mandatory;
    -webkit-scroll-snap-type: y mandatory;
    /* scroll-snap-stop: always;
    scroll-behavior: smooth; */
}

.states-container::-webkit-scrollbar {
    display: none; /* For Chrome, Safari and Opera */
}

.arrows {
  width: 30px;
  height: 36px;
  position: absolute;
  left: 50%;
  margin-left: -15px; /* Adjust this value */
  bottom: 40px;
}

.arrows path {
	stroke: #ffffff;
	fill: transparent;
	stroke-width: 1px;	
	animation: arrow 2s infinite;
	-webkit-animation: arrow 2s infinite; 
}

.arrows:hover path{
    stroke: orange;
}

@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

.arrows path.a1 {
	animation-delay:-1s;
	-webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
	animation-delay:-0.5s;
	-webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {	
	animation-delay:0s;
	-webkit-animation-delay:0s; /* Safari 和 Chrome */
}

.state1-container, .state2-container, .state3-container, .state4-container, .state5-container{
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 1000;
    background-color: transparent;
    scroll-snap-align: center;
    scroll-snap-stop: always;

}

.state6-container,.state7-container,.state8-container,.state9-container{
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 1000;
    background-color: #000;
    scroll-snap-align: center;
    scroll-snap-stop: always;

}

.state6Desktop-container, .state7Desktop-container{
    position: fixed;
    height: 100%;
    width: 30%;
    z-index: 1000;
    background-color: transparent;
    top: 10%;
}
.state6Desktop-container{
    left: 2%;
}
.state7Desktop-container{
    right: 2%;
}
.state7Desktop-body,
.state6Desktop-body{    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 85%;
    padding-top: 15%;
}

.state9-container{
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 1000;
    background-color: #000;
}

.state3-body, .state4-body{
    position: fixed;
    height: 100%;
    width: 50%;
    top: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align:center;
    font-family:'Roboto';
    align-items: center;
    font-weight:300;
    font-size:32px;
    z-index: 10000000;
}

.state3-text-container, .state4-text-container{
    border-bottom: 2px solid white;
    height: 3px;
    width: 70%;
    margin-left: 8%;
}


/* Responsive adjustments */
@media (max-width: 768px) {
    .state3-body{
        width: 100%;
        font-size:24px;
    }
    .state3-content-container{
        width: 80%;
        font-size: 1.5rem;
    }
    .snap-true-1:last-of-type span,
    .snap-true-2:last-of-type span,
    .snap-true-3:last-of-type span,
    .snap-true-4:last-of-type span,
    .snap-true-5:last-of-type span,
    .snap-true-6:last-of-type span {
        margin-left: -200px;
    }
}
.test{
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.state3-content-container{
    width: 60%;
    /* height: 10%; */
    display:inline-block;
    overflow:hidden;
    white-space:nowrap;
    color: white;
    font-size: 2rem;
    /* background-color: lightgrey; */
    opacity: 0;
    /* margin-left: 10%; */
}

.snap-false{
    transition: all 0.5s;
    opacity: 0;
}

span{


}

.snap-true-1,
.snap-true-2,
.snap-true-3,
.snap-true-4,
.snap-true-5,
.snap-true-6{
    display:inline-block;
    overflow:hidden;
    white-space:nowrap;
    opacity: 0;
    text-wrap:wrap;
    text-align:left;
  }
  
  .snap-true-1:first-of-type {
    animation: showup 7s forwards;
    animation-delay: 0s;
}

.snap-true-2:first-of-type {
    animation: showup 7s forwards;
    animation-delay: 2s;
}

.snap-true-3:first-of-type {
    animation: showup 7s forwards;
    animation-delay: 4s;
}

.snap-true-4:first-of-type {
    animation: showup 7s forwards;
    animation-delay: 6s;
}

.snap-true-5:first-of-type {
    animation: showup 7s forwards;
    animation-delay: 8s;
}

.snap-true-6:first-of-type {
    animation: showup 7s forwards;
    animation-delay: 10s;
}

.snap-true-1:last-of-type {
    animation: reveal 7s forwards;
    animation-delay: 0s;
}

.snap-true-2:last-of-type {
    animation: reveal 7s forwards;
    animation-delay: 2s;
}

.snap-true-3:last-of-type {
    animation: reveal 7s forwards;
    animation-delay: 4s;
}

.snap-true-4:last-of-type {
    animation: reveal 7s forwards;
    animation-delay: 6s;
}

.snap-true-5:last-of-type {
    animation: reveal 7s forwards;
    animation-delay: 8s;
}

.snap-true-6:last-of-type {
    animation: reveal 7s forwards;
    animation-delay: 10s;
}

.snap-true-1:last-of-type span {
    margin-left: -355px;
    animation-delay: 0s;
    animation: slidein 7s forwards;
}

.snap-true-2:last-of-type span {
    margin-left: -355px;
    animation-delay: 2s;
    animation: slidein 7s forwards;
}

.snap-true-3:last-of-type span {
    margin-left: -355px;
    animation-delay: 4s;
    animation: slidein 7s forwards;
}

.snap-true-4:last-of-type span {
    margin-left: -355px;
    animation-delay: 6s;
    animation: slidein 7s forwards;
}

.snap-true-5:last-of-type span {
    margin-left: -355px;
    animation-delay: 8s;
    animation: slidein 7s forwards;
}

.snap-true-6:last-of-type span {
    margin-left: -355px;
    animation-delay: 10s;
    animation: slidein 7s forwards;
}

  @keyframes showup {
      0% {opacity:0;}
      20% {opacity:1;}
      /* 80% {opacity:1;} */
      100% {opacity:1;}
  }
  
  @keyframes slidein {
      0% { margin-left:-800px; }
      20% { margin-left:-800px; }
      35% { margin-left:0px; }
      100% { margin-left:0px; }
  }
  
  @keyframes reveal {
    0% { opacity: 0; width: 0; }
    20% { opacity: 1; width: 0; }
    30% { opacity: 1; width: 1000px; }
    100% { opacity: 1; width: 1000px; }
}

.loader {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    max-width: 30%;
    left: 35%;
    z-index: 100;
}
.loader-text {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    max-width: 30%;
    left: 35%;
    color: orange;
    font-weight: 700;
    font-size: 1.5rem;
    z-index: 100000000;
    /* Change the color of the text shadow to orange */
    text-shadow: 0 0 20px rgba(255, 165, 0, 0.75);
}
  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    animation: pulsOut 1.8s ease-in-out infinite;
    filter: drop-shadow(0 0 1rem rgba(255, 255, 255, 0.75));
  }
  .loader:before {
    width: 100%;
    padding-bottom: 100%;
    box-shadow: inset 0 0 0 1rem #fff;
    animation-name: pulsIn;
  }
  .loader:after {
    width: calc(100% - 2rem);
    padding-bottom: calc(100% - 2rem);
    box-shadow: 0 0 0 0 #fff;
  }

  @keyframes pulsIn {
    0% {
      box-shadow: inset 0 0 0 1rem #fff;
      opacity: 1;
    }
    50%, 100% {
      box-shadow: inset 0 0 0 0 #fff;
      opacity: 0;
    }
  }

  @keyframes pulsOut {
    0%, 50% {
      box-shadow: 0 0 0 0 #fff;
      opacity: 0;
    }
    100% {
      box-shadow: 0 0 0 1rem #fff;
      opacity: 1;
    }
  }


  
.signup-modal-container{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    backdrop-filter: blur(2.5px); /* Add this line */
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.signup-modal-body{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition: all 0.3s;
    transform: scale(0);
    opacity: 0;
    position: relative;
    justify-content: space-evenly;
    /* add padding */
    padding: 1rem;    
}
.signup-modal-body.show{
    transform: scale(1);
    opacity: 1;
}
.signup-modal-body.hide{
    transform: scale(0);
    opacity: 0;
}

.logo-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.title-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #fff;
    font-family: 'Poppins';
    font-weight: 100;
    font-size: 1.8em;
}

.logo-container, .title-container {
    transition: opacity 0.1s, transform 0.1s;
}

.buttons-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
}

.modal-button{
    color: #fff;
    padding: 10px 15px; /* Decreased padding for smaller thickness */
    border-radius: 5px; /* Increased border-radius for more rounded corners */
    font-weight: 200;
    background-color: orange;
    font-family: 'Poppins';
    font-size: 1.2em;
    margin: 0 10px;
    border: 1px solid #fff;
    cursor: pointer;
    transition: transform 0.05s;
}

.modal-button:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: -180px;
    left: 0;
    width: 30px;
    height: 100%;
    background-color: #fff;
    animation: shiny-btn1 3s ease-in-out infinite;
    opacity: 0;
}

.modal-button:hover:before {
    opacity: 1;
}

@-webkit-keyframes shiny-btn1 {
    0% { -webkit-transform: scale(0) rotate(45deg); opacity: 0; }
    80% { -webkit-transform: scale(0) rotate(45deg); opacity: 0.5; }
    81% { -webkit-transform: scale(4) rotate(45deg); opacity: 1; }
    100% { -webkit-transform: scale(50) rotate(45deg); opacity: 0; }
}

@keyframes shiny-btn1 {
    0% { transform: scale(0) rotate(45deg); opacity: 0; }
    80% { transform: scale(0) rotate(45deg); opacity: 0.5; }
    81% { transform: scale(4) rotate(45deg); opacity: 1; }
    100% { transform: scale(50) rotate(45deg); opacity: 0; }
}

/* 11
.btn-11 {
    border: none;
    background: rgb(251,33,117);
      background: linear-gradient(0deg, rgba(251,33,117,1) 0%, rgba(234,76,137,1) 100%);
      color: #fff;
      overflow: hidden;
  }
  .btn-11:hover {
      text-decoration: none;
      color: #fff;
  }
  .btn-11:before {
      position: absolute;
      content: '';
      display: inline-block;
      top: -180px;
      left: 0;
      width: 30px;
      height: 100%;
      background-color: #fff;
      animation: shiny-btn1 3s ease-in-out infinite;
  }
  .btn-11:hover{
    opacity: .7;
  }
  .btn-11:active{
    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
                -4px -4px 6px 0 rgba(116, 125, 136, .2), 
      inset -4px -4px 6px 0 rgba(255,255,255,.2),
      inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
  }
  
  
  @-webkit-keyframes shiny-btn1 {
      0% { -webkit-transform: scale(0) rotate(45deg); opacity: 0; }
      80% { -webkit-transform: scale(0) rotate(45deg); opacity: 0.5; }
      81% { -webkit-transform: scale(4) rotate(45deg); opacity: 1; }
      100% { -webkit-transform: scale(50) rotate(45deg); opacity: 0; }
  } */

@import url('https://fonts.googleapis.com/css?family=Roboto:700');
@keyframes showTopText {
  0% { transform: translate3d(0, 100%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}
@keyframes showTopText2 {
    0% { transform: translate3d(0, 100%, 0); }
    40%, 60% { transform: translate3d(0, 50%, 0); }
    100% { transform: translate3d(0, 0, 0); }
  }
@keyframes showBottomText {
  0% { transform: translate3d(0, -100%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}
.animated-title {
  color: #222;
  font-family: Roboto, Arial, sans-serif;
  height: 90vmin;
  position: absolute;
  width: 90vmin;
  display: flex;
  text-align: left;

}
.animated-title > div {
  height: 50%;
  overflow: hidden;
  position: absolute;
  width: 100%;
  padding-inline: 10%;
}
.animated-title > div div {
  font-size: 9vmin;
  padding: 2vmin 0;
  position: absolute;
}
.animated-title > div div span {
  display: block;
}
/* .animated-title > div.text-top {
  border-bottom: 1vmin solid #000;
  top: 0;
} */
.animated-title > div.text-top div {
  animation: showTopText 1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
  bottom: 0;
  transform: translate(0, 100%);
}
.animated-title > div.text-top2 div {
    animation: showTopText2 1s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    bottom: 0;
    transform: translate(0, 100%);
  }
.animated-title > div.text-top div span {
  color: #ffffff;
}
.animated-title > div.text-top2 div span {
    color: #ffffff;
  }
.animated-title > div.text-bottom div{
    color: orange;
  }
.animated-title > div.text-bottom {
  bottom: 0;
}
.animated-title > div.text-bottom div {
  animation: showBottomText 0.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  top: 0;
  transform: translate(0, -100%);
}

/* Define exit animations */
@keyframes exitTopText {
    0% { transform: translate3d(0, 0, 0); }
    100% { transform: translate3d(0, 100%, 0); } /* Reversed from -100% to 100% */
}

@keyframes exitBottomText {
    0% { transform: translate3d(0, 0, 0); }
    100% { transform: translate3d(0, -100%, 0); } /* Reversed from 100% to -100% */
}

/* Apply exit animations */
.animated-title > div.text-top div.exit {
    animation: exitTopText 0.5s;
    animation-fill-mode: forwards;
}

.animated-title > div.text-bottom div.exit {
    animation: exitBottomText 0.5s;
    animation-fill-mode: forwards;
}


@keyframes exitTopText2 {
    0% { transform: translate3d(0, 0, 0); }
    100% { transform: translate3d(-500%, 0, 0); } /* Changed from vertical to horizontal translation */
}

@keyframes exitBottomText2 {
    0% { transform: translate3d(0, 0, 0); }
    100% { transform: translate3d(500%, 0, 0); } /* Changed from vertical to horizontal translation */
}

/* Apply exit animations */
.animated-title > div.text-top2 div.exit2 {
    animation: exitTopText2 0.5s;
    animation-fill-mode: forwards;
}

.animated-title > div.text-bottom div.exit2 {
    animation: exitBottomText2 0.5s;
    animation-fill-mode: forwards;
}

.state6-body,
.state7-body,
.state8-body{
    position: relative;
    top:10%;
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align:center;
    font-family:'Poppins';
    align-items: center;
    font-weight:300;

}

.state6-body .honkai-star-rail{
    height:90%;
}
.segment-heading{
    font-size: 1.7rem;
    font-weight: 500;
    color: #fff;
    /* margin-top: 1rem; */
}
.segment-content{
    font-size: 1rem;
    font-weight: 100;
    font-family: 'Poppins';
    color: #fff;
    margin-bottom: 1rem;
    line-height: 1.5;
}

.state5-body {
    position: fixed;
    top: 10%;
    min-height: 90vh;
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-family: 'Poppins';
    align-items: center;
    font-weight: 300;
}

.state5-text{
    position: absolute;
    top: 0px ;
    color: white;
    font-family:'Poppins';
    align-items: center;
    font-weight:400;
    font-size:1.5rem;
    padding-inline: 5%;
}

.divider{
    height: 2rem;
}

.color1 {
    color: orange;
}

.color2 {
    color: white;
}

.color3 {
    color: white;
}

.color4 {
    color: white;
}

.color5 {
    color: orange;
}

.redirect-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-family:'Poppins';
    font-size: 2rem;
    font-weight: 100;
    height: 50%;
}

.redirect-message{
    margin-bottom: 5rem;
}

.qr-code{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: auto;
    padding: .5rem;
    background-color: white;
    border-radius: 5px;
}



@keyframes slide-in {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}
@keyframes bounce-in {
    0% {
        transform: scale(0.1);
        opacity: 0;
    }
    60% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
    }
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:600);


.text {
    font-size: 2rem;
    position: absolute;
    top: 15%;
    left: 45%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: #fff;
    text-align: center;
}

.phrase-part-1 {
  display: inline-block;
  vertical-align: top;
  margin: 0;
}

.word {
  position: absolute;
  width: auto;
  opacity: 0;
  color:orange;
}

.letter {
  display: inline-block;
  position: relative;
  float: left;
  transform: translateZ(25px);
  transform-origin: 50% 50% 25px;
}

.letter.out {
  transform: rotateX(90deg);
  transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.letter.behind {
  transform: rotateX(-90deg);
}

.letter.in {
  transform: rotateX(0deg);
  transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@media (max-width: 768px) {
    .text {
        font-size: 1.3rem;
        position: absolute;
        top: 8%;
        left: 35%;
        transform: translate(-50%, -50%);
        width: 150%;
        color: #fff;
        text-align: center;
    }
}
