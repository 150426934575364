body {
    font-family: Arial, sans-serif;
}

h1 {
    text-align: center;
    color: #333;
}

.pricing-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    height: 90%;
    position: fixed;
    top:10%;
}

.pricing-card {
    width: 25%;
    border: 1px solid orange;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    background: linear-gradient(to bottom, transparent 50%, orange 50%);
    background-size: 100% 200%;
    transition: all .5s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.pricing-card h2 {
    color: orange;
    transition: all .5s;
    height: 10%;
    margin: 0px!important;

}
.pricing-card:hover h2 {
    color: #fff;
}


.pricing-card p {
    color: orange;
    font-weight: bold;
    transition: all .5s;
}

.pricing-card:hover p{
    color: white;
}

.pricing-card:hover {
    background-position: 0 -100%;
    transform: scale(1.1);
}
.selected{
    background-position: 0 -100%;
    transform: scale(1.1);
}
.selected h2{
    color: #fff;
}

.selected p{
    color: #fff;

}   
.pricing-switch{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3%;
}

.pricing-title{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
}

.pricing-cards-container{
    height: 60%;
    width: 80%;
    display: flex;
    justify-content: space-evenly;
}


.pricing-button{
    width: 10%;
    height: 8%;
    border: 1px solid orange;
    border-radius: 5px;
    background-color: transparent;
    color: orange;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all .5s;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    margin-bottom: 2%;

}

.pricing-button:hover{
    background-color: orange;
    color: #fff;
}

.pricing-card-image{
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pricing-card-image img{
    width: auto;
    height: 100%;
}

.pricing-card-features{
    width: 100%;
    height: 70%;
    overflow-y: auto;
}

.pricing-card-divider{
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pricing-card-divider p{
    margin: 0px!important;
}

.pricing-heading{
    width: 100%;
    font-size: 1.2rem;
}

/* For smartphones */
@media (max-width: 768px) {
    .pricing-cards-container {
        flex-direction: column;
        overflow-y: auto;
        align-items: center;
        height: 70%;
        justify-content: flex-start;
    }

    .pricing-card{
        width: 80%;
    }

    .pricing-title{
        height: 10%;
    }
    .pricing-switch{
        height: 10%;
    }

    .selected{
        transform: scale(1);
    }

    .pricing-card:hover{
        transform: scale(1);
    }

    .pricing-button{
        width: 30%;
        height: 10%;
    }
}