.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height:10%;
    display: flex;
    z-index: 10000000;
    overflow: hidden; /* Add this to contain the pseudo-element */
}

.navbar__container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.navbar__left, .navbar__right {
    width:auto;
    display: flex;
    align-items: center;
    padding-inline-start: 5%;
}

.navbar__right{
    padding-inline-end: 5%;

}

.navbar__left span {
    margin-right: 20;
    cursor: pointer;
}

.signup-button,
.blog-nav-button,
.pricing-nav-button{
    color: orange;
    padding: 5px 10px; /* Decreased padding for smaller thickness */
    border-radius: 5px; /* Increased border-radius for more rounded corners */
    font-weight: 200;
    font-family: 'Poppins';
    margin-inline: 20px;
    cursor: pointer;
}
.navbar__logo{
    color: #fff;
}

@media (max-width: 768px) {
    .signup-button,
    .pricing-nav-button{
        padding: 5px 5px; /* Decreased padding for smaller thickness */
        margin-inline: 10px;
    }
}

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #fff 0%,
    lightyellow 29%,
    yellow 67%,
    orange 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 2rem;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}