.button, .tick {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
  }
  
  
  .button {
    width: 6rem;
    height: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background: black;
    border-radius: 6px;
    transition: all .3s cubic-bezier(0.67, 0.17, 0.40, 0.83);
    color : white;
    /* position: absolute;
    left: 50%;
    transform: translateX(-50%); */
  }
  
  .button svg {
    transform: rotate(180deg);
    transition: all .5s;
  }
  
  .button__circle {
    width: 50px;
    height: 50px;
    background: orange;
    border-radius: 50%;
    transform: rotate(-180deg);
  }
  
  .button:hover {
    cursor: pointer;
  }
  
  .tick {
    color: white;
    font-size: 1em;
    transition: all .9s;
  }