
  
  input {
    font-family: 'Poppins';
    background: 0;
    border: 0;
    outline: none;
    width: 60%;
    /* max-width: 400px; */
    font-size: 1em;
    transition: padding 0.3s 0.2s ease;
    padding-bottom: 5px;
    margin-top: 15%;
  
    /* &:focus {
      padding-bottom: 5px;
    } */
  
    &:focus + .line {
      &:after {
        transform: scaleX(1);
      }
    }
  }
  
  .field {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .line {
      width: 60%;
      height: 3px;
      background: #bdc3c7;
  
      &:after {
        content: " ";
        position: absolute;
        float: right;
        width: 64%;
        height: 3px;
        
        transform: scalex(0);
        transition: transform 0.3s ease;
        
        background: #000000;
      }
    }
  }
  
  .line__red{
    width: 60%;
    height: 3px;
    background: #bdc3c7;
  
    &:after {
      content: " ";
      position: absolute;
      float: right;
      width: 64%;
      height: 3px;
      
      transform: scalex(0);
      transition: transform 0.3s ease;
      
      background: #ff0000!important;
    }
  }
  